import { Icon } from '@shared/cove-ui';
import { Button } from '@shared/cove-ui';
import { cn } from '@shared/cove-ui';
import * as Dialog from '@radix-ui/react-dialog';
import { AnimatePresence, motion } from 'framer-motion';
import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  ModalChildrenProps,
  ModalProps,
  modalAnimations,
} from './modal-constants';

let ModalContext = createContext<{
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}>({
  open: false,
  setOpen: () => {},
});

const Modal = ({
  open = false,
  onOpenChange,
  children,
  ...props
}: ModalProps) => {
  let [isOpen, setOpen] = useState(open);

  const handleOpenChange = (open: boolean) => {
    setOpen(open);
    onOpenChange?.(open);
  };

  useEffect(() => {
    handleOpenChange(open);
  }, [open]);

  return (
    <ModalContext.Provider value={{ open: isOpen, setOpen }}>
      <Dialog.Root
        modal
        open={isOpen}
        onOpenChange={handleOpenChange}
        {...props}
      >
        {children}
      </Dialog.Root>
    </ModalContext.Provider>
  );
};

function ModalTrigger({ children }: { children: ReactNode }) {
  return <Dialog.Trigger asChild>{children}</Dialog.Trigger>;
}

Modal.Trigger = ModalTrigger;

function ModalClose({ children }: { children: ReactNode }) {
  return <Dialog.Close asChild>{children}</Dialog.Close>;
}

Modal.Close = ModalClose;

let DropdownMenuContext = createContext({ closeMenu: () => {} });

function ModalChildren({
  children,
  className,
  overlayClasses,
  CloseButton,
  noCloseButton = false,
  animation = 'default',
  ...props
}: ModalChildrenProps) {
  const [mounted, setMounted] = useState(false);
  let { open, setOpen } = useContext(ModalContext);

  useEffect(() => {
    setMounted(true);
  }, []);

  function closeMenu() {
    setOpen(false);
  }

  return (
    <DropdownMenuContext.Provider value={{ closeMenu }}>
      <AnimatePresence>
        {open && mounted && (
          <Dialog.Portal forceMount>
            <Dialog.Overlay asChild forceMount>
              <motion.div
                initial={{
                  opacity: 0,
                }}
                animate={{
                  opacity: 1,
                }}
                exit={{
                  opacity: 0,
                }}
                className={cn(
                  'fixed inset-0 z-40 w-screen bg-black/50 ',
                  overlayClasses
                )}
              />
            </Dialog.Overlay>
            <Dialog.Content asChild forceMount {...props}>
              <motion.div
                initial={modalAnimations[animation].initial}
                animate={modalAnimations[animation].animate}
                exit={modalAnimations[animation].exit}
                className={cn(
                  'minimal-scroll-bar fixed left-1/2 top-1/2 z-50 max-h-[calc(100vh-50px)] min-h-[50px] w-full min-w-[137px] max-w-[calc(100vw-30px)] -translate-x-1/2 -translate-y-1/2 overflow-x-hidden border-4 border-transparent bg-cove-dove p-1 shadow',
                  className
                )}
              >
                {children}
                {!noCloseButton && (
                  <Dialog.Close asChild>
                    {CloseButton ? (
                      <CloseButton aria-label="Close" onClick={closeMenu} />
                    ) : (
                      <Button
                        className="fixed right-0 top-0"
                        size="small"
                        variant="text"
                        aria-label="Close"
                        onClick={closeMenu}
                      >
                        <Icon icon="Cancel" />
                      </Button>
                    )}
                  </Dialog.Close>
                )}
              </motion.div>
            </Dialog.Content>
          </Dialog.Portal>
        )}
      </AnimatePresence>
    </DropdownMenuContext.Provider>
  );
}

Modal.Content = ModalChildren;

export default Modal;
